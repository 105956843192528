.level_text {
  font-size: 16px;
}

input[type=text], input:not([type='checkbox']), select {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  border-color: gray;
  border-width: 1px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  border-radius: 4px;
  border-color: gray;
  border-width: 1px;
  padding-left: 10px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  align-self: center;
  display: flex;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 10%;
  z-index: 1;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.submit_button {
  width: 100%;
  padding: 8px;
  border: 0;
  border-radius: 5px;
  background-color: #4690D1;
  color: white;
}

thead {
  color: black;
  background-color: #d3d8de;
}

.item-list-image-size {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.icon-size-of-action {
  width: 25px;
  height: 25px;
  margin: 5px;
}

.no-list-item-text {
  text-align: center;
}

.item-details-title-text {
  font-size: 25px;
  color: #4690D1;
  font-weight: bold;
}

.item-details-text {
  font-size: 20px;
  color: gray;
  margin-bottom: 30px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.upload-image-btn {
  border: 1px solid gray;
  background-color: white;
  padding: 8px 20px;
  width: 250px;
  border-radius: 4px;
  font-size: 16px;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.remove_button {
  background-color: brown;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: 0;
  width: 250px;
}

.image_preview {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.back_button {
  float: right;
  border: 0;
  background-color: #417DAF;
  color: white; 
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
}

.excel_icon_size {
  width: 50px;
  height: 50px;
  align-self: center;
  margin: 5px
}

.file_name_text {
  text-align: center;
  font-weight: bold;
}

/* ---------------------------name---------------------------- (design css)*/
.line {
  display: block;
  margin: 25px
}

.line h2 {
  font-size: 20px;
  text-align: center;
  border-bottom: 1px solid gray;
  position: relative;
}

.line h2 span {
  background-color: white;
  position: relative;
  top: 10px;
  padding: 0 10px;
}

.list_search_button{
  border: 0;
  width: 100%;
  background-color: #D3D8DE;
  padding: 7px;
  margin-top: 5px;
}

/* tooltip */

/* send push notification design */
.check_box_container{
  width: 25px;
  height: 25px;
}

.notification_container {
  display: flex;
  margin-top: 30px;
}

.notification_text {
  padding-left: 20px;
  cursor: default;
}

