/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
}

.sidebar_logo {
  width: 100%;
  padding: 15px;
  margin-top: 56px;
}

.sidebar_item_name_without_child {
  border-bottom: 1px solid gray;
  margin-top: 15px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.sidebar_item_name_with_child {
  margin-top: 15px;
  font-size: 16px;
  margin-left: 10px;
  border-bottom: 1px solid gray;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.sidebar_dropdown_icon {
  width: 10px;
  height: 10px;
  float: right;
  /* margin-left: 100px; */
  /* margin-top: 10px; */
  cursor: pointer;
}

.sidebar_child_item {
  border-bottom: 1px solid gray;
  font-size: 14px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.sidebar_item:hover {
  text-decoration: none;
}

.sidebar_router_link {
  color: #c2c2c2;
}
.sidebar_router_link_inactive {
  color: #c2c2c2;
}
.sidebar_router_link_inactive:hover {
  color: #c2c2c2;
  background-color: rgb(70, 66, 66);
}

.sidebar_router_link_active {
  color: black;
  background-color: #ffc107;
  /* color: white;
  background-color: rgb(88, 85, 85); */
}
.sidebar_router_link_active:hover {
  color: black;
  /* color: white; */
}

.sidebar_without_chikd_router_link {
  color: white;
}

/* div:last-child { border-bottom: none; } */
